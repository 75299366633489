import React from "react";
import classNames from "classnames";
import { Link } from "gatsby";
import { useState } from "react";

import { useUser } from "$hooks/auth";

import NavItem from "$ui/navItem";
import Anchor from "$ui/anchor";

import logoImg from "$images/logo.png";

import MenuIcon from "$assets/menu.svg";
import UserIcon from "$assets/user.svg";

const SubMenu = ({ className, children }) => {
  return (
    <ul
      className={classNames(
        "transition-opacity shadow-lg text-black space-y-1 invisible left-0 opacity-0 absolute h-42 w-40 bg-white rounded-lg p-2 group-hover:opacity-100 group-hover:visible",
        className
      )}
    >
      {children}
    </ul>
  );
};

const NavBar = () => {
  const [mobileNav, setMobileNav] = useState(false);
  const { user, isLoading: isLoadingUser } = useUser();

  return (
    <nav
      className={classNames(
        mobileNav === true
          ? "fixed w-full h-full flex justify-between items-center px-10 backdrop-filter backdrop-blur-lg saturate-200 bg-white bg-opacity-80 z-50 shadow-2xl tab-port:pl-0 phone:pr-6"
          : "fixed w-full flex justify-between  items-center px-10 backdrop-filter backdrop-blur-lg saturate-200 bg-white bg-opacity-80 z-50 shadow-2xl tab-port:pl-0 phone:pr-6"
      )}
    >
      
      <Link to="/">
        
        <div className="flex justify-start items-center max-w-sm gap-1">
           <img src={logoImg} alt="LOGO" className="h-24  object-contain" />
            <h1 className=" font-bold text-lg text-center">Consumer Rights <br/> Organisation</h1>
        </div>
   </Link>



      <ul className="flex space-x-10 tab-port:hidden">
        <NavItem href="/" matchExactly={true}>
          home
        </NavItem>
        <NavItem href="/about">
          About
          {/* <SubMenu>
            <li>
              <Link to='/about'>About CRO</Link>
            </li>
            <li>
              <Link to='/about/con-act#consumerActs'>About Consumer Act</Link>
            </li>
            <li>
              <Link to='/about/con-rights#consumerRights'>
                About Consumer Rights
              </Link>
            </li>
            <li>
              <Link to='/letters'>Certificates</Link>
            </li>
          </SubMenu> */}
        </NavItem>
        {/* <NavItem href="/committee">
          committee
          <SubMenu>
            <li>
              <Link to="/committee#nationalPatron">National Patron</Link>
            </li>
            <li>
              <Link to="/committee#nationalWorking">
                National Working Committee
              </Link>
            </li>
            <li>
              <Link to="/committee#presidents">State presidents</Link>
            </li>
          </SubMenu>
        </NavItem> */}
        {/* <NavItem href="/letters">letters</NavItem>
        <NavItem href="/gallery">
          gallery
          <SubMenu>
            <li>
              <Link to="/gallery/#image">Photos</Link>
            </li>
            <li>
              <Link to="/gallery/video#videos">Videos</Link>
            </li>
          </SubMenu>
        </NavItem> */}
        <NavItem href="/contact">contacts</NavItem>
        <NavItem href="/complaint">complaint</NavItem>
      </ul>

      {isLoadingUser || (!isLoadingUser && !user) ? (
        <Anchor variant="filled" href="/auth/login" className="tab-port:hidden">
          Sign in
        </Anchor>
      ) : (
        <Anchor
          variant="filled"
          href={
            user.role === "user" || user.role === "member"
              ? "/me/dashboard"
              : "/admin/dashboard"
          }
          className="flex items-center tab-port:hidden"
        >
          <UserIcon className="h-5" />
          <span>{user.fullName.split(" ")[0]}</span>
        </Anchor>
      )}

      <button
        aria-label="nav-opener"
        className="hidden tab-port:block"
        onClick={() => setMobileNav(true)}
      >
        <MenuIcon />
      </button>

      {mobileNav && (
        <div className="fixed h-full w-full left-0 top-0 bg-blue-100 z-40 flex items-center justify-center">
          <button
            className="absolute top-0 right-5 text-6xl p-4"
            onClick={() => setMobileNav(false)}
          >
            &times;
          </button>

          <ul className="space-y-6 text-2xl text-center">
            <li>
              <Link
                partiallyActive={true}
                onClick={() => setMobileNav(false)}
                to="/"
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                partiallyActive={true}
                onClick={() => setMobileNav(false)}
                to="/about"
              >
                About
              </Link>
            </li>
            <li>
              <Link
                partiallyActive={true}
                onClick={() => setMobileNav(false)}
                to="/complaint"
              >
                Complaint
              </Link>
            </li>
            <li>
              <Link
                partiallyActive={true}
                onClick={() => setMobileNav(false)}
                to="/#footer"
              >
                Contact
              </Link>
            </li>
            {/* <li>
              <Link
                partiallyActive={true}
                onClick={() => setMobileNav(false)}
                to="/gallery"
              >
                Gallery
              </Link>
            </li>
            <li>
              <Link
                partiallyActive={true}
                onClick={() => setMobileNav(false)}
                to="/committee"
              >
                Committee
              </Link>
            </li>
            <li>
              <Link
                partiallyActive={true}
                onClick={() => setMobileNav(false)}
                to="/letters"
              >
                Letters
              </Link>
            </li> */}

            {isLoadingUser || (!isLoadingUser && !user) ? (
              <li>
                <Link onClick={() => setMobileNav(false)} to="/auth/login">
                  Login
                </Link>
              </li>
            ) : (
              <li>
                <Link onClick={() => setMobileNav(false)} to="/me/dashboard">
                  My Account
                </Link>
              </li>
            )}
          </ul>
        </div>
      )}
    </nav>
  );
};
export default NavBar;
