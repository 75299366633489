import React from 'react';
import classNames from 'classnames';

import ArrowRight from '$assets/pointarrow.svg';

const Point = ({ children, variant = 'white' }) => (
  <li className='flex items-center'>
    <ArrowRight
      className={classNames({
        'fill-white': variant === 'white',
        'fill-black': variant === 'black',
        'fill-primary': variant === 'primary'
      })}
    />
    {children}
  </li>
);

export default Point;
