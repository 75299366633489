import React from "react";
import { Link } from "gatsby";

import Point from "$ui/point";

import FacebookIcon from "$assets/facebook.svg";
import InstagramIcon from "$assets/instagram.svg";
import TwitterIcon from "$assets/twitter.svg";
import LinkedinIcon from "$assets/linkedin.svg";

import logoImg from "$images/logo.png";

const Footer = () => (
  <footer
    id="footer"
    className="grid grid-cols-4 gap-y-20 py-16 px-20 bg-gray-800 text-white tab-land:grid-cols-none phone:px-10"
  >
    <div className="tab-land:col-span-full tab-land:flex tab-land:justify-between phone:flex-col phone:items-center">
      <img
        src={logoImg}
        alt="LOGO"
        className=" h-56 mb-12 tab-land:mb-0 phone:mb-8"
      />

      <Link to="/complaint" className="pb-3 border-b-2 border-gray-100 ml-4">
        File a complaint &nbsp; &rarr;
      </Link>
    </div>

    <div>
      <h3 className="font-semibold text-3xl">Quick links</h3>

      <ul className="mt-8">
        <Point>
          <Link to="/#section-testimonial">Testimonials</Link>
        </Point>
        <Point>
          <Link to="/our-team">Our Team</Link>
        </Point>
        <Point>
          <Link to="/refund-policy">Refund Policy</Link>
        </Point>
        <Point>
          <Link to="/terms-condition">Terms & Conditions</Link>
        </Point>
        <Point>
          <Link to="/privacy-policy">Privacy Policy</Link>
        </Point>
        <Point>
          <Link to="/faqs">FAQs</Link>
        </Point>
      </ul>
    </div>

    <div>
      <h3 className="font-semibold text-3xl">Important links</h3>

      <ul className="mt-8">
        <a target="_blank" rel="noreferrer" href="https://india.gov.in">
          <Point variant="white">https://india.gov.in</Point>
        </a>
        <a target="_blank" rel="noreferrer" href="https://www.mygov.in/">
          <Point variant="white">https://wwww.mygov.in</Point>
        </a>
        <a target="_blank" rel="noreferrer" href="https://ncdrc.nic.in/">
          <Point variant="white">https://ncdrc.nic.in</Point>
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://consumeraffairs.nic.in"
        >
          <Point variant="white">https://consumeraffairs.nic.in</Point>
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://consumerhelpline.gov.in"
        >
          <Point variant="white">https://consumerhelpline.gov.in</Point>
        </a>
      </ul>
    </div>

    <div className="space-y-6">
      <h3 className="font-semibold text-3xl">Contact us</h3>

      <p>
        Thecro.org website is a dedicated consumer complaint portal for the members of the
organization. We welcome your questions and comments regarding the complaint
website. If you have any questions/queries call or email us.
      </p>
      <p>Email : info@thecro.org</p>
      <p>Phone: +91 9977-252-252</p>
    </div>

    <div className="col-span-full flex justify-center space-x-12">
      <a
        href="https://facebook.com/"
        rel="noreferrer"
        target="_blank"
        aria-label="FACEBOOK"
      >
        <FacebookIcon fill="#ffffff" />
      </a>
      <a
        href="https://linkedin.com/"
        rel="noreferrer"
        target="_blank"
        aria-label="LINKEDIN"
      >
        <LinkedinIcon fill="#ffffff" />
      </a>
      <a
        href="https://instagram.com/"
        rel="noreferrer"
        target="_blank"
        aria-label="INSTAGRAM"
      >
        <InstagramIcon fill="#ffffff" />
      </a>
      <a
        href="https://twitter.com/"
        rel="noreferrer"
        target="_blank"
        aria-label="TWITTER"
      >
        <TwitterIcon fill="#ffffff" />
      </a>
    </div>
  </footer>
);

export default Footer;
