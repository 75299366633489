import React from 'react';
import { ToastContainer } from 'react-toastify';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import Layout from '$components/layout/guest';

import 'react-toastify/dist/ReactToastify.css';
import '$styles/global.css';

const queryClient = new QueryClient();

export const wrapPageElement = ({ element, props: { path } }) => (
  <QueryClientProvider client={queryClient}>
    {path.includes('/me/') || path.includes('/admin/') ? (
      element
    ) : (
      <Layout>{element}</Layout>
    )}
    <ToastContainer />
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
);
