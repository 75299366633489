import axios from '$services/api';

// ////////////////////////////////////////////////////////////
export const getLocation = async code => {
  const {
    data: {
      data: { pincode }
    }
  } = await axios.get(`/pincodes/${code}`);

  return pincode;
};

// ////////////////////////////////////////////////////////////
export const getMe = async () => {
  const {
    data: {
      data: { user }
    }
  } = await axios.get('/users/me');

  return user;
};

export const updateMe = async requestBody => {
  const {
    data: {
      data: { user }
    }
  } = await axios.patch('/users/me', requestBody);

  return user;
};

export const loginUser = async requestBody => {
  const {
    data: { data }
  } = await axios.post('/users/login', requestBody);

  return data;
};

export const signupUser = async requestBody => {
  const {
    data: { data }
  } = await axios.post('/users/signup', requestBody);

  return data;
};

// ////////////////////////////////////////////////////////////
export const createComplaint = async requestBody => {
  const {
    data: { data }
  } = await axios.post('/complaints', requestBody);

  return data;
};

export const getMyComplaints = async () => {
  const {
    data: {
      data: { complaints }
    }
  } = await axios.get('/users/me/complaints');

  return complaints;
};

export const getAllComplaints = async () => {
  const {
    data: {
      data: { complaints }
    }
  } = await axios.get('/complaints');

  return complaints;
};

export const getComplaintById = async queryFnContext => {
  const { queryKey } = queryFnContext;
  const [, complaintId] = queryKey;

  const {
    data: {
      data: { complaint }
    }
  } = await axios.get(`/complaints/${complaintId}`);

  return complaint;
};

export const getComplaintStatistics = async () => {
  const {
    data: {
      data: { statistics }
    }
  } = await axios.get('/complaints/statistics');

  return statistics;
};

export const updateComplaint = async mutationParams => {
  const { complaintId, ...requestBody } = mutationParams;

  const {
    data: {
      data: { complaint }
    }
  } = await axios.patch(`/complaints/${complaintId}`, requestBody);

  return complaint;
};

// ////////////////////////////////////////////////////////////
export const getRazorpayOrderForComplaint = async id => {
  const {
    data: {
      data: { order }
    }
  } = await axios.get(`/payments/complaint/${id}`);

  return order;
};

// ////////////////////////////////////////////////////////////
export const getAllUsers = async () => {
  const {
    data: {
      data: { users }
    }
  } = await axios.get('/users');

  return users;
};

// ////////////////////////////////////////////////////////////
export const getMembershipCheckOut = async requestBody => {
  const {
    data: {
      data: { order }
    }
  } = await axios.post('/payments/membership', requestBody);

  return order;
};
