import React from 'react';
import { Link } from 'gatsby';

const NavItem = ({ href, children, matchExactly = false }) => (
  <li className='capitalize relative before:absolute before:h-[2px] before:w-full before:bg-primary before:-bottom-2 before:transition-transform before:duration-500 before:origin-left before:scale-x-0 hover:before:scale-x-100 group'>
    <Link
      partiallyActive={!matchExactly}
      to={href}
      activeClassName='text-primary'>
      {children}
    </Link>
  </li>
);

export default NavItem;
