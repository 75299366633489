import React from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';

const Anchor = ({
  children,
  className,
  href = '/',
  variant = 'outlined',
  size = 'big'
}) => (
  <Link
    to={href}
    className={classNames(
      'text-center rounded-lg inline-block transition-all',
      size === 'small'
        ? 'text-xs py-1 px-3 min-w-[3rem] phone:text-atmoic'
        : 'px-6 py-2 min-w-[7rem] border-2',
      className,
      {
        'border-white text-white': variant === 'outlined',
        'border-primary text-primary hover:bg-gray-100':
          variant === 'outlined-colored',
        'border-primary bg-primary text-white hover:bg-primary-dark hover:border-primary-dark':
          variant === 'filled',
        'border-white bg-white text-primary font-semibold tracking-wide hover:bg-gray-100 hover:border-gray-100':
          variant === 'filled-plain'
      }
    )}>
    {children}
  </Link>
);

export default Anchor;
