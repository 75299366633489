exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-con-act-js": () => import("./../../../src/pages/about/con-act.js" /* webpackChunkName: "component---src-pages-about-con-act-js" */),
  "component---src-pages-about-con-rights-js": () => import("./../../../src/pages/about/con-rights.js" /* webpackChunkName: "component---src-pages-about-con-rights-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-admin-account-js": () => import("./../../../src/pages/admin/account.js" /* webpackChunkName: "component---src-pages-admin-account-js" */),
  "component---src-pages-admin-complaints-[id]-js": () => import("./../../../src/pages/admin/complaints/[id].js" /* webpackChunkName: "component---src-pages-admin-complaints-[id]-js" */),
  "component---src-pages-admin-complaints-index-js": () => import("./../../../src/pages/admin/complaints/index.js" /* webpackChunkName: "component---src-pages-admin-complaints-index-js" */),
  "component---src-pages-admin-dashboard-js": () => import("./../../../src/pages/admin/dashboard.js" /* webpackChunkName: "component---src-pages-admin-dashboard-js" */),
  "component---src-pages-admin-users-js": () => import("./../../../src/pages/admin/users.js" /* webpackChunkName: "component---src-pages-admin-users-js" */),
  "component---src-pages-auth-forgot-password-js": () => import("./../../../src/pages/auth/forgot-password.js" /* webpackChunkName: "component---src-pages-auth-forgot-password-js" */),
  "component---src-pages-auth-login-js": () => import("./../../../src/pages/auth/login.js" /* webpackChunkName: "component---src-pages-auth-login-js" */),
  "component---src-pages-auth-reset-password-js": () => import("./../../../src/pages/auth/reset-password.js" /* webpackChunkName: "component---src-pages-auth-reset-password-js" */),
  "component---src-pages-auth-signup-js": () => import("./../../../src/pages/auth/signup.js" /* webpackChunkName: "component---src-pages-auth-signup-js" */),
  "component---src-pages-committee-js": () => import("./../../../src/pages/committee.js" /* webpackChunkName: "component---src-pages-committee-js" */),
  "component---src-pages-complaint-js": () => import("./../../../src/pages/complaint.js" /* webpackChunkName: "component---src-pages-complaint-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-gallery-index-js": () => import("./../../../src/pages/gallery/index.js" /* webpackChunkName: "component---src-pages-gallery-index-js" */),
  "component---src-pages-gallery-video-js": () => import("./../../../src/pages/gallery/video.js" /* webpackChunkName: "component---src-pages-gallery-video-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-letters-js": () => import("./../../../src/pages/letters.js" /* webpackChunkName: "component---src-pages-letters-js" */),
  "component---src-pages-me-account-js": () => import("./../../../src/pages/me/account.js" /* webpackChunkName: "component---src-pages-me-account-js" */),
  "component---src-pages-me-complaints-[id]-js": () => import("./../../../src/pages/me/complaints/[id].js" /* webpackChunkName: "component---src-pages-me-complaints-[id]-js" */),
  "component---src-pages-me-complaints-index-js": () => import("./../../../src/pages/me/complaints/index.js" /* webpackChunkName: "component---src-pages-me-complaints-index-js" */),
  "component---src-pages-me-dashboard-js": () => import("./../../../src/pages/me/dashboard.js" /* webpackChunkName: "component---src-pages-me-dashboard-js" */),
  "component---src-pages-membership-js": () => import("./../../../src/pages/membership.js" /* webpackChunkName: "component---src-pages-membership-js" */),
  "component---src-pages-our-team-js": () => import("./../../../src/pages/our-team.js" /* webpackChunkName: "component---src-pages-our-team-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-refund-policy-js": () => import("./../../../src/pages/refund-policy.js" /* webpackChunkName: "component---src-pages-refund-policy-js" */),
  "component---src-pages-terms-condition-js": () => import("./../../../src/pages/terms-condition.js" /* webpackChunkName: "component---src-pages-terms-condition-js" */)
}

