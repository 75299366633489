import React from 'react';

import NavBar from './navBar';
import Footer from './footer';

const Layout = ({ children }) => (
  <>
    <NavBar />
    {children}
    <Footer />
  </>
);

export default Layout;
